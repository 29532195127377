/**
 * Created by Administrator on 2019/10/2.
 * 存放常量
 */
export default {
    loginNameType: {
        phone: 1,
        email: 2
    },
    //发送验证码类型
    sendCodeType: {
        register: 1,//注册
        reset: 2//重置
    },
    interfaceResult: {
        success: 0,
        forbidden: 403,
        notFound: 404
    },
    vipType: {
        normal: 1,//一般用户
        standard: 2,//一般vip
        senior:4,//高级
        business:5,//企业
    },
    //头部菜单
    headerMenu: [
        {
            name: "素材代下",
            link: "materialDownload"
        },
        // {
        //     name: "素材中心",
        //     link: "materialCenter"
        // },
        {
            name: "微信客服",
            link: ""
        },
        // {
        //     name: "会员特权",
        //     link: "privilege"
        // },
        // {
        //     name: "在线客服",
        //     link: ""
        // }
        // {
        //     name: "会员特权",
        //     link: "privilege"
        // },
    ],
    //头部下拉菜单
    headerMoreMenus: [
        {
            name: "素材代下",
            link: "materialDownload"
        }
        // {
        //     name: "其他素材网",
        //     link: "homeDownload"
        // }
        // {
        //     name: "素材中心",
        //     link: "materialCenter"
        // },
        // {
        //     name: "会员特权",
        //     link: "privilege"
        // }
    ],
    headerUserMenus: [
        // {
        //     name: "快速充值",
        //     link: "pay"
        // },
        // {
        //     name: "个人中心",
        //     link: "personlCenter"
        // },
        {
            name: "我的下载记录",
            link: "downloadHistory"
        },
        // {
        //     name: "消息中心",
        //     link: ""
        // },
        {
            name: "退出登录",
            link: "login"
        }
    ],
    asideMenus: [
        // {
        //     name: "主页",
        //     link: "home"
        // },
        // {
        //     name: "其他素材网",
        //     link: "homeDownload"
        // },
        {
            name: "下载历史",
            link: "downloadHistory"
        },
        {
            name: "快速充值",
            link: "pay"
        },
        {
            name: "卡劵充值",
            link: "cardStockPay"
        },
        // {
        //     name: "素材中心",
        //     link: "materialCenter"
        // },
        // {
        //     name: "会员特权",
        //     link: "privilege"
        // },
        // {
        //     name: "推荐有奖",
        //     link: ""
        // },
        {
            name: "使用声明",
            link: "statement"
        }
        // {
        //     name: "邀请赚钱",
        //     link: "personlCenter"
        // }
    ]
}

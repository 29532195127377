import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import menu from "./modules/menu";
import common from "./modules/common.js";
import getters from "./getters";
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})
const store = new Vuex.Store({
  modules: {
    user,
    menu,
    common
  },
  getters,
  plugins: [vuexLocal.plugin]
});

export default store;

import Vue from "vue";
import App from "./App.vue";
import routes from "@/router/router";
import VueRouter from "vue-router";
import Vuex from 'vuex'
import store from "@/store/index";
import constants from '@/constants'
import Element from 'element-ui';
import '@/assets/styles/index.scss';
import '@/assets/iconfont/iconfont.css';
import filters from './filter/filter'
import { message } from '@/utils/resetMessage';
//import loading from 'vue-nice-loading';
import Loading from 'vue-loading-spin';
import 'vue-loading-spin/dist/loading.css';

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes
})

Vue.use(Element)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Loading, {
    isFixed: true, // 是否固定位置，默认为true
    isComponent: true, // 是否自动注册为全局组件，如果设置为false就不会注册成组件来使用
    isShowAnimation: true // 是否显示动画效果，默认为true
});
Vue.config.productionTip = false;
Vue.prototype.constants = constants
Vue.prototype.$message = message;
Vue.prototype.$EventBus = new Vue()
window.globalStore = store
window.constants = constants
window.globalRouter = router
window.globalEventHub = new Vue()
window.globalRoutes = routes

window.addEventListener('beforeunload', function () {
    sessionStorage.setItem('userMsg', JSON.stringify(window.globalStore.state))
})

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key])
})

// 路由处理
router.beforeEach((to, from, next) => {
    if (sessionStorage.getItem('userMsg')) { // 代表有刷新
        globalStore.replaceState(JSON.parse(sessionStorage.getItem('userMsg')))
        sessionStorage.removeItem('userMsg')
    }
    //是否显示侧边栏以及头部
    let showLeft = false;
    let showHeader = false;
    showLeft = to.meta.hideSide ? false : true;
    showHeader = to.meta.hideHeader ? false : true;
    globalStore.dispatch('setShowLeftSlider', showLeft);
    globalStore.dispatch('setShowHeader', showHeader);
    console.log(JSON.stringify(to.path));
    // if (to.path === '/') {
    //     router.push({
    //         name: '/'
    //     })
    //     /*router.push({
    //         name: '404'
    //     })*/
    // }else
    if(to.path.search('/temporaryDownload') > -1 || to.path.search('frontIndex') || to.path.search('/tempDown') > -1){

    }else if(to.name !== 'login'){
        if ((window.globalStore.state.user.loginNameType == null || window.globalStore.state.user.loginNameType == '') ||
            (window.globalStore.state.user.token == null)) {
            router.push({name: 'login'})
        }
        /*router.push({
            name: '404'
        })*/
    }
    next();
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
